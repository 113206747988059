import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Fade from "react-reveal/Fade"

const SecondPage = () => (
  <Layout>
    <SEO title="Services" />
    <div className="hero hero-subpage services">
      <Fade bottom>
        <div className="container p-0 text-center">
          <div className="hero-text-heading">WHAT I DO</div>
        </div>
      </Fade>
    </div>
    <div className="pattern border_bottom">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-12 py-5">
            Take a look at the assortment of services I offer below. If you do
            not see a service listed, please do not hesitate to reach out. I am highly adaptable and would be happy to discuss your project!
            <br />
            <br />
            You may also view these samples to get a sense of the quality and
            standards of my writing.<br/>
            <ul className="samples">
            <br/><b>Feature Articles</b>
              <li>
                <a
                  href="http://digital.petcompanionmag.com/publication/?m=40178&amp;i=680028&amp;p=44"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Understanding Pet Food Labels
                </a>
              </li>
              <br/><b>Educational Articles</b>
              <li>
                <a
                  href="https://www.healthypawspetinsurance.com/blog/2020/03/12/cat-pneumonia-symptoms-diagnosis-and-treatment"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cat Pneumonia
                </a>
              </li>
              <li>
                <a
                  href="https://www.healthypawspetinsurance.com/blog/2020/03/24/when-do-dogs-stop-growing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  When Do Dogs Stop Growing
                </a>
              </li>
              <li>
                <a
                  href="https://blog.healthypawspetinsurance.com/triaditis-in-cats"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Triaditis in Cats
                </a>
              </li>
              <li>
                <a
                  href="https://blog.healthypawspetinsurance.com/what-to-do-if-your-dog-is-having-a-seizure"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  What to Do If Your Dog is Having a Seizure
                </a>
              </li>
              <li>
                <a
                  href="https://blog.healthypawspetinsurance.com/what-is-canine-distemper"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  What is Canine Distemper?
                </a>
              </li>
              <li>
                <a
                  href="https://blog.healthypawspetinsurance.com/dog-eats-a-dead-animal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  What to Do If Your Dog Eats a Dead Animal
                </a>
              </li>
              <li>
                <a
                  href="https://www.greatpetcare.com/cat-health/unhealthy-cat-teeth/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Signs of Unhealthy Cat Teeth
                </a>
              </li>
              <li>
                <a
                  href="https://www.greatpetcare.com/dog-health/dog-obesity-risks-symptoms-solutions/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dog Obesity
                </a>
              </li>
              <li>
                <a
                  href="https://www.greatpetcare.com/cat-health/is-my-cat-overweight/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Is My Cat Overweight?
                </a>
              </li>
              <li>
                <a
                  href="https://www.greatpetcare.com/wellness/is-citronella-safe-for-dogs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Is Citronella Safe for Dogs?
                </a>
              </li>
              <br/><b>Medication Summaries</b>
              <li>
                <a
                  href="https://www.greatpetcare.com/pet-medication/trifexis-for-dogs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Trifexis for Dogs
                </a>
              </li>
              <li>
                <a
                  href="https://www.greatpetcare.com/pet-medication/tylan-powder-for-dogs/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tylan Powder for Dogs
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid servicesList">
      <div className="row">
        <div className="col-md-6 bg-image edu"></div>
        <div className="col-md-6 py-5 text-center my-auto">
          <h3 className="text-uppercase">Pet Parent Education</h3>
          <ul className="m-0">
            <li>Medical Handouts</li>
            <li>Pet Care Resources</li>
            <li>Disease Summaries</li>
            <li>Online Veterinary Content</li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 bg-image prof_mats order-sm-1 order-md-12"></div>
        <div className="col-md-6 py-5 text-center my-auto order-sm-12 order-md-1">
          <h3 className="text-uppercase">Professional Materials</h3>
          <ul className="m-0">
            <li>Continuing Education</li>
            <li>Exam Questions</li>
            <li>Online Learning Modules</li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 bg-image doctor_research "></div>
        <div className="col-md-6 py-5 text-center my-auto">
          <h3 className="text-uppercase">Medical News</h3>
          <ul className="m-0">
            <li>Research Summaries</li>
            <li>Educational Articles</li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
)

export default SecondPage
